<template>
  <div class="wysiwyg">
    <div class="flex lg:flex-wrap flex-wrap-reverse pb-10">
      <div class="w-full lg:w-1/2 px-4">
        <div class="text-center lg:mt-0 mt-8">
          <img
            class="inline-block w-[170px] lg:w-[220px]"
            src="@/assets/role.png"
            alt=""
          />
        </div>
      </div>
      <div class="w-full lg:w-1/2 px-4">
        <div class="lg:mt-12 border border-gray-500 p-6 rounded-lg">
          <span class="block mb-4">
            遇到困難了嗎?<br />
            酷獸小幫手來幫助你 !
          </span>
          <div>
            <a href="#space" class="block text-xl text-gray-800 font-bold"
              >策展教學</a
            >
            <a href="#faq" class="block mt-1 text-xl text-gray-800 font-bold"
              >常見問題</a
            >
          </div>
        </div>
      </div>
    </div>
    <h2 class="" id="space">策展教學</h2>
    <p class="mt-8 !mb-0">
      策展過程中遇到困難了嗎? 請點擊下列影片教學，可以幫助大家更快理解及上手喔!
    </p>
    <ol class="list-decimal ml-5">
      <li v-for="row in videos" :key="row.title">
        <span class="text-primary">「{{ row.title }}」</span
        ><a
          href="#"
          @click.prevent="toggleVideo(row.link)"
          target="_blank"
          class="link"
          >點我看完整教學</a
        >
      </li>
    </ol>

    <h2 class="!mt-12" id="faq">常見問題</h2>

    <div v-for="row in faqs" :key="row.id">
      <h3>{{ row.q }}</h3>
      <p>{{ row.a }}</p>
    </div>
  </div>
  <el-dialog
    v-model="show"
    width="60%"
    append-to-body
    destroy-on-close
    @closed="videouri = ''"
    custom-class="cover"
  >
    <div class="" style="aspect-ratio: 16/9">
      <YT-Player
        ref="youtube"
        :videoid="videouri"
        :loop="0"
        :width="'100%'"
        :height="'100%'"
        :autoplay="1"
        :playervars="{
          showinfo: 0,
          rel: 0,
          modestbranding: 1,
        }"
      />
    </div>
  </el-dialog>
</template>
<script>
import YTPlayer from '@/components/YTPlayer.vue';
import { ref, reactive } from 'vue';
import { ElDialog } from 'element-plus';
export default {
  components: { ElDialog, YTPlayer },
  setup() {
    const videos = reactive([
      {
        id: 'Backstage-login',
        title: '後台登入',
        link: 'https://youtu.be/-ukFh5CS4NI',
      },
      {
        id: 'Add-exhibition',
        title: '新增展覽',
        link: 'https://youtu.be/n9b_ijsFyDI',
      },
      {
        id: 'New-exhibits',
        title: '新增展品',
        link: 'https://youtu.be/eUToBVCsXKQ',
      },
      {
        id: 'Imported-exhibits-from-other-exhibitions',
        title: '匯入其他展品清單',
        link: 'https://youtu.be/QXF3qiQwjOo',
      },
      {
        id: 'Edit-exhibition',
        title: '編輯展場',
        link: 'https://youtu.be/zlWCL-NNPDA',
      },
      {
        id: 'Edit-exhibit-details',
        title: '編輯展品細節',
        link: 'https://youtu.be/7QmjG-6Y9Cc',
      },
      {
        id: 'Add-text',
        title: '新增文字',
        link: 'https://youtu.be/G42wY7SDFyc',
      },
      {
        id: 'Add-picture',
        title: '新增媒體',
        link: 'https://youtu.be/eXJWnV3bLAA',
      },
      {
        id: 'Live-tour-setting',
        title: '動線參觀設定',
        link: 'https://youtu.be/s4cdvgn5y_E',
      },
      {
        id: 'Setting-Profile',
        title: '設定總說',
        link: 'https://youtu.be/Wv9vi9VWxNM',
      },
      {
        id: 'Set-the-summary-main-visual-poster',
        title: '設定主視覺海報',
        link: 'https://youtu.be/1HM4pY35doE',
      },
      {
        id: 'Preview-booth',
        title: '預覽展間',
        link: 'https://youtu.be/ZMCzbviop1w',
      },
      {
        id: 'Preview-the-details-of-the-work',
        title: '預覽作品細節',
        link: 'https://youtu.be/8eXYKtFYFrA',
      },
    ]);

    const faqs = reactive([
      {
        q: '(1) 請問要如何開始動手策展呢? ',
        a: '答: 先選擇要展示的展品→設定展間尺寸→設定展間的隔間→自由拖拉展品至平面空間擺放，完成之後儲存設定，可先行預覽展覽空間效果，如果檢視都沒問題，可按下一步設定視覺海報，最後送出審核即可。',
        id: '1',
      },
      {
        q: '(2) 要如何把展品加進去選單裡?',
        a: '答: 點選「新增展品」，即進入展品頁面，可依需求條件搜尋理想的展品，最後點選展品，系統會直接將選擇的展品列入展品欄位，供策展人拖拉至展場設置。',
        id: '2',
      },
      {
        q: '(3) 要怎麼確認展牆、展品之間的距離與空間感?',
        a: '答: 平面圖的方格以每30公分為一格單位，策展人可自行評估間距設置。另外，當展品拖拉至展場時會有系統設置圓點與直線，此為系統設置最佳觀賞距離「站點」，因此在規劃展牆隔間時，請注意距離要超過該圓點線，以防展牆擋住作品而看不到。',
        id: '3',
      },
      {
        q: '(4) 當展品拖拉至展場，我怎麼知道哪一面是正面或背面?',
        a: '答: 展品拖拉至展場時，會看到有系統設置圓點與直線，圓點線的那一面為展品正面，意即觀賞點為此面，可再自行旋轉方向。',
        id: '4',
      },
      {
        q: '(5) 不確定拖拉至展場的展品是否有放置到正確的位置或空位，可以如何確認?',
        a: '答: 展間平面圖可按滑鼠滾動放大與縮小，或是按滑鼠左鍵自由移動，幫助確認展覽規劃與設計。',
        id: '5',
      },
      {
        q: '(6) 平面圖只能看大致位置，如果要調整更細節的高度位置，如何調整?',
        a: '答: 可點選展場上方的「編輯物件」，點進去後可在此處調整展品的水平位移與高度，亦可直接點擊展品兩下，進入編輯物件畫面進行編輯。',
        id: '6',
      },
      {
        q: '(7) 設置立體物件如雕塑、器皿展品，要如何設置展台?',
        a: '答: 立體展品的展台高度及寬度，系統已經以該作品尺寸自動設置完成，策展人不需要再自行設置，但可以更改展台顏色與材質。',
        id: '7',
      },
      {
        q: '(8) 展場如果要放年表牆或作者介紹等文字，是直接去「新增文字」區設置嗎?',
        a: '答: 「新增文字」物件區，建議以總說、分區介紹等簡短文字設置，如需更有設計感的排版，或是如文字較多的年表牆等，建議直接設計成一張圖片，並以「新增圖片」方式放置，會更為美觀及達到效果。',
        id: '8',
      },
      {
        q: '(9) 展牆顏色可以更換嗎? ',
        a: '答: 可以，點擊展牆2下，可直接選擇顏色更換；唯展場四周牆面顏色需一致，不能單面更換，展場四周牆面顏色調整，直接點選展牆2下，可更換色彩或材質，兩者僅能選其一，不能同時使用。',
        id: '9',
      },
      {
        q: '(10) 預覽完展場，發現還有想變動的地方可以回去更改嗎?',
        a: '答: 可以，預覽完展場點選關閉預覽，可回到編輯畫面繼續調整，記得最後設定完按儲存，之後若再登入進系統後，點選該展覽仍可繼續編輯。',
        id: '10',
      },
      {
        q: '(11) 系統內建的展品沒有符合我需求，可以自行上傳要展的展品嗎?',
        a: '答: 不可以，如果需要自行上傳展品，需要和雲端博物館管理員申請，通過審核後才能進行，策展人沒有權限可執行。而系統內的展品說明，策展人可依展覽規劃，自行詮釋及更改展品說明，唯展品品名、尺寸等基本資訊不能更改。',
        id: '11',
      },
    ]);
    const show = ref(false);
    const videouri = ref('');
    const getYouTubeID = (url) => {
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/u);
      /* eslint-disable no-useless-escape */
      return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/iu)[0] : url[0];
    };
    const toggleVideo = (url) => {
      show.value = !show.value;
      videouri.value = show.value ? getYouTubeID(url) : '';
    };
    return { faqs, videos, toggleVideo, show, videouri };
  },
};
</script>
